import { graphql, useStaticQuery } from 'gatsby';

import Layout from 'layouts/landing';

import CustomerReviews from 'components/content-blocks/customer-reviews/customer-reviews';
import CustomerStories from 'components/content-blocks/customer-stories';
import MediaObject from 'components/content-blocks/media-object';
import MobileFeatures from 'components/content-blocks/mobile-features';
import ROICalculator from 'components/content-blocks/roi-calculator';
import TemplateVsWiw from 'components/content-blocks/template-vs-wiw';
import Yoast from 'components/globals/yoast';
import { HeroDefaultSignup } from 'components/hero/default-signup';

import './styles/free-employee-scheduling.scss';

export default function FreeEmployeeScheduling() {

  const data = useStaticQuery(graphql`
    {
      allWordpressPage(filter: { slug: { eq: "free-employee-scheduling" } }) {
        edges {
          node {
            wordpress_id
        yoast_head_json {
          title
          description
          canonical
          og_title
          og_description
          og_image {
            url {
              source_url
            }
          }
          og_url
          og_type
          twitter_title
          twitter_description
          twitter_image {
            source_url
          }
        }
            acf {
              hero {
                title
                copy
                signup {
                  launch_modal_button_text
                  modal_title
                  modal_submit_button_text
                  unique_id
                }
              }
              media_object {
                title
                sub_title
                body
                desktop_image {
                  alt_text
                  source_url
                }
                mobile_image {
                  alt_text
                  source_url
                }
              }
              features_grid_mobile_1 {
                title
                sub_title
                buttons {
                  button_1 {
                    icon_from_library
                    icon_width
                    title
                    body
                    desktop_screenshot {
                      source_url
                    }
                    mobile_screenshot {
                      source_url
                    }
                  }
                  button_2 {
                    icon_from_library
                    icon_width
                    title
                    body
                    desktop_screenshot {
                      source_url
                    }
                    mobile_screenshot {
                      source_url
                    }
                  }
                  button_3 {
                    icon_from_library
                    icon_width
                    title
                    body
                    desktop_screenshot {
                      source_url
                    }
                    mobile_screenshot {
                      source_url
                    }
                  }
                  button_4 {
                    icon_from_library
                    icon_width
                    title
                    body
                    desktop_screenshot {
                      source_url
                    }
                    mobile_screenshot {
                      source_url
                    }
                  }
                }
              }
              customer_stories {
                card_groups {
                  card {
                    card_link
                    card_link_title
                    sub_title
                    title
                    image {
                      alt_text
                      source_url
                    }
                  }
                }
                card_section_title
                section {
                  copy
                  sub_title
                  title
                }
              }
              template_vs_wiw {
                Wiw {
                  body
                  desktop_image {
                    source_url
                  }
                  logo {
                    url {
                      source_url
                    }
                  }
                  mobile_image {
                    source_url
                  }
                  title
                  comparison_list {
                    line_1 {
                      icon
                      copy
                    }
                    line_2 {
                      icon
                      copy
                    }
                    line_3 {
                      icon
                      copy
                    }
                    line_4 {
                      icon
                      copy
                    }
                  }
                }
                template {
                  body
                  desktop_image {
                    source_url
                  }
                  mobile_image {
                    source_url
                  }
                  title
                  comparison_list {
                    line_1 {
                      icon
                      copy
                    }
                    line_2 {
                      icon
                      copy
                    }
                    line_3 {
                      icon
                      copy
                    }
                    line_4 {
                      icon
                      copy
                    }
                  }
                }
              }
            }
          }
        }
      }
      wordpressAcfOptions {
        options {
          customer_reviews {
            title
            sub_title
            customer {
              name
              company
              quote
              image {
                source_url
              }
            }
          }
        }
      }
    }
  `);

  const yoast = data.allWordpressPage.edges[0].node.yoast_head_json;
  const customerReviews = data.wordpressAcfOptions.options.customer_reviews;
  const hero = data.allWordpressPage.edges[0].node.acf.hero;
  const mediaObject = data.allWordpressPage.edges[0].node.acf.media_object;
  const mobileFeatures =
    data.allWordpressPage.edges[0].node.acf.features_grid_mobile_1;
  const customerStories =
    data.allWordpressPage.edges[0].node.acf.customer_stories;
  const templateVsWiw = data.allWordpressPage.edges[0].node.acf.template_vs_wiw;

  return (
    <Layout>
      <Yoast { ...yoast } />
      <div className="free-employee-scheduling-page-container" data-signal={ data.allWordpressPage.edges[0].node.wordpress_id }>
        <HeroDefaultSignup signupSingleButton { ...hero } />
        <TemplateVsWiw className="reverse" { ...templateVsWiw[0] } />
        <ROICalculator />
        <CustomerReviews { ...customerReviews } hasImage hasAwards />
        <MediaObject { ...mediaObject[0] } />
        <MediaObject className="reverse" { ...mediaObject[1] } />
        <MobileFeatures { ...mobileFeatures } />
        <CustomerStories { ...customerStories } hasNoIndustryTitles />
      </div>
    </Layout>
  );
}
